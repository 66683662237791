define("shared/components/cluster-driver/driver-aks/component", ["exports", "ipaddr.js", "semver", "shared/mixins/cluster-driver", "shared/utils/constants", "shared/utils/util", "ui/models/cluster", "ui/utils/azure-choices", "shared/components/cluster-driver/driver-aks/template"], function (_exports, _ipaddr, _semver, _clusterDriver, _constants, _util, _cluster, _azureChoices, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NETWORK_POLICY = [{
    label: 'None',
    value: null
  }, {
    label: 'Calico',
    value: 'calico'
  }, {
    label: 'Azure (requires Azure CNI)',
    value: 'azure',
    disabled: true
  }];
  const CHINA_REGION_API_URL = 'https://management.chinacloudapi.cn/';
  const CHINA_REGION_AUTH_URL = 'https://login.chinacloudapi.cn/';
  const NETWORK_PLUGINS = [{
    label: 'Kubenet',
    value: 'kubenet'
  }, {
    label: 'Azure CNI',
    value: 'azure'
  }];
  const LB_SKUS = [{
    label: 'Standard',
    value: 'Standard'
  }, {
    label: 'Basic',
    value: 'Basic'
  }];

  var _default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    versionChoiceService: Ember.inject.service('version-choices'),
    layout: _template.default,
    configField: 'aksConfig',
    clusterAdvanced: false,
    clusterErrors: null,
    clusterLocationSaved: false,
    clusterLocationSaving: false,
    disableAzs: false,
    enabledAuthorizedIpRanges: false,
    errors: null,
    lbSkus: LB_SKUS,
    loadBalancerImmutable: false,
    monitoringRegionConent: [],
    networkPlugins: NETWORK_PLUGINS,
    otherErrors: null,
    regions: null,
    step: 1,
    versions: null,
    vmSizes: null,
    regionsWithAZs: _azureChoices.regionsWithAZs,
    defaultNodePoolConfig: _cluster.DEFAULT_AKS_NODE_POOL_CONFIG,
    defaultAksConfig: _cluster.DEFAULT_AKS_CONFIG,
    defaultK8sVersionRange: Ember.computed.alias(`settings.${_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE}`),
    editing: Ember.computed.equal('mode', 'edit'),
    isNew: Ember.computed.equal('mode', 'new'),
    allErrors: Ember.computed.union('errors', 'otherErrors', 'clusterErrors'),

    init() {
      this._super(...arguments);

      let config = Ember.get(this, 'cluster.aksConfig');

      if (!config) {
        config = this.globalStore.createRecord(JSON.parse(JSON.stringify(this.defaultAksConfig)));
        const defNodePool = this.globalStore.createRecord(JSON.parse(JSON.stringify(this.defaultNodePoolConfig)));
        Ember.setProperties(defNodePool, {
          isNew: true,
          name: 'agentpool'
        });
        Ember.set(config, 'nodePools', [defNodePool]);
        Ember.setProperties(this, {
          'cluster.aksConfig': config,
          'loadBalancerSku': 'Standard'
        });
      } else {
        var _config;

        if (this.editing && this.importedClusterIsPending || this.clusterIsPending && (_config = config) !== null && _config !== void 0 && _config.privateCluster) {
          Ember.set(this, 'step', 4);
        } else {
          var _config2;

          this.syncUpstreamConfig();
          const tags = { ...(config.tags || {})
          };
          Ember.set(this, 'tags', tags);

          if (!Ember.isEmpty((_config2 = config) === null || _config2 === void 0 ? void 0 : _config2.authorizedIpRanges)) {
            Ember.set(this, 'enabledAuthorizedIpRanges', true);
          }
        }
      }
    },

    actions: {
      addNodePool() {
        const config = Ember.get(this, `primaryResource.aksConfig`);
        const kubernetesVersion = Ember.get(config, 'kubernetesVersion');
        let nodePools = (Ember.get(config, 'nodePools') || []).slice();
        const npConfig = JSON.parse(JSON.stringify(this.defaultNodePoolConfig));

        if (!Ember.isArray(nodePools)) {
          nodePools = [];
        }

        const nodePool = this.globalStore.createRecord(npConfig);
        Ember.set(nodePool, 'mode', 'User');
        Ember.setProperties(nodePool, {
          orchestratorVersion: kubernetesVersion,
          isNew: true
        });
        nodePools.pushObject(nodePool);
        Ember.set(this, 'config.nodePools', nodePools);
      },

      removeNodePool(nodePool) {
        let {
          config: {
            nodePools = []
          }
        } = this;

        if (!Ember.isEmpty(nodePools)) {
          nodePools.removeObject(nodePool);
        }

        Ember.set(this, 'config.nodePools', nodePools);
      },

      updateAuthorizedIpRanges(ipRanges) {
        Ember.set(this, 'config.authorizedIpRanges', ipRanges);
      },

      finishAndSelectCloudCredential(cred) {
        if (cred) {
          Ember.set(this, 'config.azureCredentialSecret', Ember.get(cred, 'id'));
          this.send('loadRegions', (ok, error) => {
            if (!ok) {
              this.send('errorHandler', error);
            }
          });
        }
      },

      async loadRegions(cb) {
        const store = Ember.get(this, 'globalStore');
        const {
          selectedCloudCredential,
          regionsWithAZs
        } = this;
        const data = {
          cloudCredentialId: Ember.get(selectedCloudCredential, 'id') // tenantId:          get(this, 'config.tenantId'),

        };
        const url = (0, _util.addQueryParams)('/meta/aksLocations', data);

        try {
          const regions = await store.rawRequest({
            url,
            method: 'GET'
          });
          const filteredRegions = ((regions === null || regions === void 0 ? void 0 : regions.body) ?? []).map(reg => {
            if (regionsWithAZs.includes((reg === null || reg === void 0 ? void 0 : reg.displayName) || '')) {
              return { ...reg,
                ...{
                  group: 'High Availablity'
                }
              };
            } else {
              return { ...reg,
                ...{
                  group: 'Other'
                }
              };
            }
          });
          Ember.set(this, 'regions', filteredRegions);
          cb(true);
          Ember.set(this, 'step', 2);

          if (this.editing) {
            Ember.set(this, 'clusterLocationSaving', true);
            this.send('authenticate', (ok, error) => {
              if (!ok) {
                this.send('errorHandler', error);
              }

              Ember.set(this, 'clusterLocationSaving', false);
            });
          }
        } catch (error) {
          cb(false, error);
        }
      },

      authenticate(cb) {
        const store = Ember.get(this, 'globalStore');
        const {
          selectedCloudCredential
        } = this;
        const data = {
          cloudCredentialId: Ember.get(selectedCloudCredential, 'id'),
          // tenantId:          get(this, 'config.tenantId'),
          region: Ember.get(this, 'config.resourceLocation'),
          clusterId: Ember.get(this, 'cluster.id')
        };

        if (Ember.get(this, 'isChinaRegion')) {
          Ember.setProperties(data, {
            baseUrl: CHINA_REGION_API_URL,
            authBaseUrl: CHINA_REGION_AUTH_URL
          });
        }

        const versionsUrl = (0, _util.addQueryParams)('/meta/aksVersions', data);
        const upgradesUrl = (0, _util.addQueryParams)('/meta/aksUpgrades', data);
        const vNetsUrl = (0, _util.addQueryParams)('/meta/aksVirtualNetworks', data);
        const vmSizes = (0, _util.addQueryParams)('/meta/aksVMSizes', data);
        const aksRequest = {
          versions: store.rawRequest({
            url: versionsUrl,
            method: 'GET'
          }),
          upgradeVersions: data.clusterId ? store.rawRequest({
            url: upgradesUrl,
            method: 'GET'
          }) : Promise.resolve({}),
          virtualNetworks: store.rawRequest({
            url: vNetsUrl,
            method: 'GET'
          }),
          vmSizes: store.rawRequest({
            url: vmSizes,
            method: 'GET'
          })
        };
        return Ember.RSVP.hash(aksRequest).then(resp => {
          const {
            mode
          } = this;
          const {
            versions,
            upgradeVersions,
            virtualNetworks,
            vmSizes
          } = resp;
          const isEdit = mode === 'edit';
          const versionz = Ember.get(versions, 'body') || [];
          const upgradeVersionz = Ember.get(upgradeVersions, 'body.upgrades') || [];
          const initialVersion = isEdit ? this.config.kubernetesVersion : _semver.default.maxSatisfying(versionz, this.defaultK8sVersionRange); // default in azure ui

          if (!isEdit && initialVersion) {
            Ember.set(this, 'cluster.aksConfig.kubernetesVersion', initialVersion);
          }

          const enabledVersions = upgradeVersionz.filter(upgrade => upgrade.enabled).map(upgrade => upgrade.version);
          Ember.setProperties(this, {
            step: 3,
            versions: enabledVersions.length > 0 ? [initialVersion, ...enabledVersions] : versionz,
            virtualNetworks: Ember.get(virtualNetworks, 'body') || [],
            vmSizes: (vmSizes === null || vmSizes === void 0 ? void 0 : vmSizes.body) || []
          });
          cb(true);
        }).catch(xhr => {
          const err = xhr.body.message || xhr.body.code || xhr.body.error;
          Ember.setProperties(this, {
            errors: [err]
          });
          cb(false, [err]);
        });
      },

      setTags(section) {
        Ember.set(this, 'config.tags', section);
      }

    },
    resetNetworkPolicy: Ember.observer('config.{networkPolicy,networkPlugin}', function () {
      const {
        networkPolicy,
        networkPlugin
      } = this.config;

      if (networkPlugin === 'kubenet' && networkPolicy === 'azure') {
        Ember.set(this, 'config.networkPolicy', null);
      }
    }),
    resetIpRanges: Ember.observer('config.privateCluster', 'loadBalancerSku', function () {
      var _this$config, _this$config2;

      if (((_this$config = this.config) !== null && _this$config !== void 0 && _this$config.privateCluster || this.loadBalancerSku === 'Basic') && !Ember.isEmpty((_this$config2 = this.config) === null || _this$config2 === void 0 ? void 0 : _this$config2.authorizedIpRanges)) {
        Ember.setProperties(this, {
          'config.authorizedIpRanges': [],
          enabledAuthorizedIpRanges: false
        });
      }
    }),
    resourceLocationChanged: Ember.observer('config.resourceLocation', function () {
      const {
        regions,
        config: {
          resourceLocation
        }
      } = this;
      const match = regions.findBy('name', resourceLocation);
      const regionHasAz = this.regionsWithAZs.includes((match === null || match === void 0 ? void 0 : match.displayName) || '');

      if (regionHasAz) {
        Ember.set(this, 'disableAzs', false);
      } else {
        Ember.set(this, 'disableAzs', true);
      }
    }),
    postSaveChanged: Ember.observer('isPostSave', function () {
      const {
        isNew,
        isPostSave,
        config: {
          privateCluster
        },
        importedClusterIsPending
      } = this;

      if ((privateCluster || importedClusterIsPending) && isPostSave) {
        if (isNew) {
          Ember.set(this, 'step', 4);
        } else {
          this.close();
        }
      } else {
        this.close();
      }
    }),
    availablityZonesChanged: Ember.on('init', Ember.observer('config.nodePools.[]', function () {
      const nodePools = Ember.get(this, 'config.nodePools') || [];
      const azs = [];
      nodePools.forEach(np => {
        if (np !== null && np !== void 0 && np.availabilityZones && np.availabilityZones.length > 0) {
          azs.pushObjects(np.availabilityZones);
        }
      });
      const anySet = azs.uniq().any(az => az);

      if (anySet) {
        Ember.setProperties(this, {
          'loadBalancerSku': 'Standard',
          'loadBalancerImmutable': true
        });
      } else {
        Ember.set(this, 'loadBalancerImmutable', false);
      }
    })),
    resetAdvancedOptions: Ember.on('init', Ember.observer('config.networkPlugin', function () {
      if (Ember.get(this, 'isNew') && Ember.get(this, 'config.networkPlugin') === 'azure') {
        const config = Ember.get(this, 'config');
        const defaultConfigOptions = {
          dnsServiceIp: '10.0.0.10',
          dockerBridgeCidr: '172.17.0.1/16',
          podCidr: '172.244.0.0/16',
          serviceCidr: '10.0.0.0/16',
          subnet: '',
          virtualNetwork: '',
          virtualNetworkResourceGroup: ''
        };
        const {
          subnet,
          virtualNetwork,
          virtualNetworkResourceGroup,
          serviceCidr,
          dnsServiceIp,
          dockerBridgeCidr
        } = defaultConfigOptions;
        Ember.setProperties(config, {
          subnet,
          virtualNetwork,
          virtualNetworkResourceGroup,
          serviceCidr,
          dnsServiceIp,
          dockerBridgeCidr
        });
      }
    })),
    monitoringConfigDisabled: Ember.computed('model.originalCluster.aksStatus.upstreamSpec.monitoring', 'isNewOrEditable', 'editing', function () {
      const {
        isNewOrEditable,
        editing
      } = this;
      const upstreamMonitoringEnabled = Ember.get(this, 'model.originalCluster.aksStatus.upstreamSpec.monitoring') ?? false;

      if (isNewOrEditable) {
        return false;
      }

      if (editing && !upstreamMonitoringEnabled) {
        return false;
      }

      return true;
    }),
    importedClusterIsPending: Ember.computed('clusterIsPending', 'model.originalCluster', function () {
      const {
        clusterIsPending
      } = this;
      const originalCluster = Ember.get(this, 'model.originalCluster');
      const ourClusterSpec = Ember.get(originalCluster ?? {}, 'aksConfig');
      const upstreamSpec = Ember.get(originalCluster ?? {}, 'aksStatus.upstreamSpec');
      return clusterIsPending && Ember.get(ourClusterSpec, 'imported') && !Ember.isEmpty(upstreamSpec);
    }),
    clusterIsPending: Ember.computed('clusterState', function () {
      const {
        clusterState
      } = this;
      return ['pending', 'provisioning', 'waiting'].includes(clusterState);
    }),
    anyWindowsNodes: Ember.computed('config.nodePools.@each.osType', function () {
      var _this$config3;

      const nodePools = (this === null || this === void 0 ? void 0 : (_this$config3 = this.config) === null || _this$config3 === void 0 ? void 0 : _this$config3.nodePools) ?? [];

      if (Ember.isEmpty(nodePools)) {
        return false;
      }

      return nodePools.any(np => (np === null || np === void 0 ? void 0 : np.osType) === 'Windows');
    }),
    hasProvisioned: Ember.computed('model.cluster', function () {
      const cluster = Ember.get(this, 'model.cluster');
      const {
        state = '',
        isError = false
      } = cluster;
      let clusterHasProvisioned = true;

      if (isError && state === 'provisioning') {
        var _cluster$aksStatus;

        if (Ember.isEmpty(cluster === null || cluster === void 0 ? void 0 : (_cluster$aksStatus = cluster.aksStatus) === null || _cluster$aksStatus === void 0 ? void 0 : _cluster$aksStatus.upstreamSpec)) {
          clusterHasProvisioned = false;
        }
      }

      return clusterHasProvisioned;
    }),
    isNewOrEditable: Ember.computed('hasProvisioned', 'isNew', 'mode', function () {
      const isNew = Ember.get(this, 'isNew');

      if (isNew) {
        return true;
      }

      return this.mode === 'edit' && !this.hasProvisioned;
    }),
    cloudCredentials: Ember.computed('model.cloudCredentials', function () {
      const {
        model: {
          cloudCredentials
        }
      } = this;
      return cloudCredentials.filter(cc => {
        const isAzure = Object.prototype.hasOwnProperty.call(cc, 'azurecredentialConfig');

        if (isAzure) {
          return true;
        }

        return false;
      });
    }),
    selectedCloudCredential: Ember.computed('config.azureCredentialSecret', function () {
      const {
        model: {
          cloudCredentials = []
        },
        config: {
          azureCredentialSecret
        }
      } = this;

      if (Ember.isEmpty(cloudCredentials) && Ember.isEmpty(azureCredentialSecret)) {
        return null;
      } else {
        return cloudCredentials.findBy('id', azureCredentialSecret.includes('cattle-global-data:') ? azureCredentialSecret : `cattle-global-data:${azureCredentialSecret}`);
      }
    }),
    versionChoices: Ember.computed('versions', function () {
      const {
        versions = [],
        mode,
        config: {
          kubernetesVersion: initialVersion
        }
      } = this; // azure versions come in oldest to newest

      return this.versionChoiceService.parseCloudProviderVersionChoices((versions || []).reverse(), initialVersion, mode);
    }),
    networkChoice: Ember.computed({
      set(_key, value = '') {
        const [subnet, virtualNetwork, virtualNetworkResourceGroup] = value.split(':');
        const config = Ember.get(this, 'config');

        if (subnet && virtualNetwork && virtualNetworkResourceGroup) {
          Ember.setProperties(config, {
            subnet,
            virtualNetwork,
            virtualNetworkResourceGroup
          });
        }

        return value;
      }

    }),
    filteredVirtualNetworks: Ember.computed('config.virtualNetwork', 'virtualNetworks', function () {
      const vnets = Ember.get(this, 'virtualNetworks') || [];
      const subNets = [];
      vnets.forEach(vnet => {
        (Ember.get(vnet, 'subnets') || []).forEach(subnet => {
          subNets.pushObject({
            name: `${Ember.get(subnet, 'name')} (${Ember.get(subnet, 'addressRange')})`,
            group: Ember.get(vnet, 'name'),
            value: `${Ember.get(subnet, 'name')}:${Ember.get(vnet, 'name')}:${Ember.get(vnet, 'resourceGroup')}`
          });
        });
      });
      return subNets;
    }),
    networkChoiceDisplay: Ember.computed('virtualNetworks', 'config.virtualNetwork', 'config.subnet', function () {
      const selected = (Ember.get(this, 'virtualNetworks') || []).findBy('name', Ember.get(this, 'config.virtualNetwork')) || {};
      const subnet = (Ember.get(selected, 'subnets') || []).findBy('name', Ember.get(this, 'config.subnet')) || {};
      return `${Ember.get(subnet, 'name')} (${Ember.get(subnet, 'addressRange')})`;
    }),
    isChinaRegion: Ember.computed('config.resourceLocation', function () {
      return Ember.get(this, 'config.resourceLocation').startsWith('china');
    }),
    networkPolicyContent: Ember.computed('config.networkPlugin', function () {
      var _this$config4;

      const netPolicies = [...NETWORK_POLICY];
      const azureNetPolicy = netPolicies.findBy('value', 'azure');

      if ((this === null || this === void 0 ? void 0 : (_this$config4 = this.config) === null || _this$config4 === void 0 ? void 0 : _this$config4.networkPlugin) === 'azure') {
        Ember.set(azureNetPolicy, 'disabled', false);
      } else {
        Ember.set(azureNetPolicy, 'disabled', true);
      }

      return netPolicies;
    }),

    validate() {
      const intl = Ember.get(this, 'intl');
      let model = Ember.get(this, 'cluster');
      let clusterErrors = model.validationErrors() || [];
      const nodePoolErrors = this.validateNodePools();
      const vnetSet = !!Ember.get(this, 'config.virtualNetwork');

      if (vnetSet) {
        clusterErrors = clusterErrors.concat(this.validateVnetInputs());
      }

      if (this.isNew && !Ember.get(this, 'config.resourceGroup')) {
        clusterErrors.push(intl.t('validation.required', {
          key: intl.t('clusterNew.azureaks.resourceGroup.label')
        }));
      }

      if (this.isNew && !Ember.get(this, 'config.dnsPrefix')) {
        clusterErrors.push(intl.t('validation.required', {
          key: intl.t('clusterNew.azureaks.dns.label')
        }));
      }

      Ember.set(this, 'errors', [...(this.errors ?? []), ...clusterErrors, ...nodePoolErrors]);
      return this.errors.length === 0;
    },

    validateNodePools() {
      const nodePools = Ember.get(this, 'primaryResource.aksConfig.nodePools');
      const errors = [];

      if (!Ember.isEmpty(nodePools)) {
        const nodePoolErrors = [];
        nodePools.forEach(np => {
          const npErr = np.validationErrors();
          const npOs = np === null || np === void 0 ? void 0 : np.osType;
          const npName = np === null || np === void 0 ? void 0 : np.name; // aka.ms/aks-naming-rules

          if (npOs === 'Linux') {
            if (npName.length > 11) {
              errors.push(this.intl.t('clusterNew.azureaks.nodePools.errors.linuxName'));
            }
          } else if (npOs === 'Windows') {
            if (npName.length > 6) {
              errors.push(this.intl.t('clusterNew.azureaks.nodePools.errors.windowsName'));
            }
          }

          if (!/^[a-z][a-z0-9]+$/.test(npName)) {
            errors.push(this.intl.t('clusterNew.azureaks.nodePools.errors.nameFormat'));
          }

          nodePoolErrors.push(npErr);
        });

        if (!Ember.isEmpty(nodePoolErrors)) {
          errors.pushObjects(nodePoolErrors.flat());
        }
      }

      return errors;
    },

    validateVnetInputs() {
      const intl = Ember.get(this, 'intl');
      const errors = [];
      const config = Ember.get(this, 'config');
      const vnet = Ember.get(this, 'virtualNetworks').findBy('name', Ember.get(config, 'virtualNetwork'));

      if (vnet) {
        let subnet = Ember.get(vnet, `subnets`).findBy('name', Ember.get(config, 'subnet'));

        let vnetRange = _ipaddr.default.parseCIDR(Ember.get(subnet, 'addressRange'));

        let {
          serviceCidr,
          dnsServiceIp,
          dockerBridgeCidr
        } = config;
        let parsedServiceCidr = null;
        let parsedDnsServiceIp = null;
        let parsedDockerBridgeCidr = null;

        if (!serviceCidr && !dnsServiceIp && !dockerBridgeCidr) {
          errors.pushObject('You must include all required fields when using a Virtual Network');
        }

        try {
          parsedServiceCidr = _ipaddr.default.parseCIDR(serviceCidr); // check if serviceCidr falls within the VNet/Subnet range

          if (parsedServiceCidr && vnetRange[0].match(parsedServiceCidr)) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedServiceCidr'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.serviceCidr'));
        }

        try {
          parsedDnsServiceIp = _ipaddr.default.parse(dnsServiceIp); // check if dnsService exists in range

          if (parsedDnsServiceIp && vnetRange[0].match(parsedDnsServiceIp, vnetRange[1])) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedDnsServiceIp'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.dnsServiceIp'));
        }

        try {
          parsedDockerBridgeCidr = _ipaddr.default.parseCIDR(dockerBridgeCidr); // check that dockerBridge doesn't overlap

          if (parsedDockerBridgeCidr && (vnetRange[0].match(parsedDockerBridgeCidr) || parsedServiceCidr[0].match(parsedDockerBridgeCidr))) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedDockerBridgeCidr'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.dockerBridgeCidr'));
        }
      }

      return errors;
    },

    willSave() {
      const enableMonitoring = Ember.get(this, 'config.monitoring');
      const config = Ember.get(this, 'config');

      if (!enableMonitoring) {
        Ember.setProperties(config, {
          logAnalyticsWorkspaceResourceGroup: null,
          logAnalyticsWorkspace: null
        });
      }

      Ember.set(config, 'clusterName', this.cluster.name);

      if (Ember.get(this, 'isChinaRegion')) {
        Ember.setProperties(config, {
          baseUrl: CHINA_REGION_API_URL,
          authBaseUrl: CHINA_REGION_AUTH_URL
        });
      } else {
        delete config['baseUrl'];
        delete config['authBaseUrl'];
      }

      Object.keys(config).forEach(k => {
        const val = Ember.get(config, k);

        if (Ember.isEmpty(val)) {
          delete config[k];
        }
      });
      return this._super(...arguments);
    },

    syncUpstreamConfig() {
      const originalCluster = Ember.get(this, 'model.originalCluster').clone();
      const ourClusterSpec = Ember.get(originalCluster, 'aksConfig');
      const upstreamSpec = Ember.get(originalCluster, 'aksStatus.upstreamSpec');

      if (!Ember.isEmpty(upstreamSpec)) {
        Object.keys(upstreamSpec).forEach(k => {
          if (Ember.isEmpty(Ember.get(ourClusterSpec, k)) && !Ember.isEmpty(Ember.get(upstreamSpec, k))) {
            Ember.set(this, `config.${k}`, Ember.get(upstreamSpec, k));
          }
        });
      }
    }

  });

  _exports.default = _default;
});